import React from "react"
import { Link } from "gatsby"
import { navigate } from '@reach/router';

import './header.scss'
import bladImage from './images/blad.svg'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
    };

    this.toggleEvent = this.toggleEvent.bind(this);
    this.goToMother = this.goToMother.bind(this);
  }
  toggleEvent() {
    this.setState({isToggleOn: !this.state.isToggleOn});
  }
  goToMother() {
    this.props.treeRef.current.selectMother();
    navigate('/#bana-1000000');
  }
  render () {
    return (
      <header className={`header ${this.state.isToggleOn ? '' : 'header--small'}`}>
        <img className="header__image" src={bladImage} alt="A graphic leaf" />
        <h1 className="header__title">The <span>Bana Fam</span> Tree</h1>
        <div className="header__toggle" onClick={this.toggleEvent} role="button" tabIndex={0}>
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 960 814.3" style={{enableBackground:"new 0 0 960 814.3"}}>
            <g id="Rounded_Rectangle_33_copy_4_1_">
              <path className="header__toggle-down" d="M480,344.2L268.9,131.9c-15.8-15.9-41.3-15.9-57.1,0c-15.8,15.9-15.8,41.6,0,57.4l237.6,238.9
                c8.4,8.5,19.6,12.3,30.6,11.7c11,0.6,22.2-3.2,30.6-11.7l237.6-238.9c15.8-15.9,15.8-41.6,0-57.4s-41.3-15.9-57.1,0L480,344.2z"/>
            </g>
            <g id="Rounded_Rectangle_33_copy_4_2_">
              <path className="header__toggle-up" d="M480,459.4l211.1,212.3c15.8,15.9,41.3,15.9,57.1,0c15.8-15.9,15.8-41.6,0-57.4L510.6,375.3
                c-8.4-8.5-19.6-12.3-30.6-11.7c-11-0.6-22.2,3.2-30.6,11.7L211.8,614.3c-15.8,15.9-15.8,41.6,0,57.4s41.3,15.9,57.1,0L480,459.4z"
                />
            </g>
          </svg>
        </div>
        <div className="header__text">
          <p>The Bana Fam Tree is the genealogical chart or ancestral tree of a particular family of Banana plants. Scroll and explore to see are latest members!</p>
          <ul>
            <li><span>
              <Link to="/#bana-1000000" onClick={this.goToMother}>Start at the top</Link>
            </span></li>
          <ul></ul>
            <li><span>
              <a target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLScctmEn9NuVVt57wpz8IPzRK1cFBbkfYCQwrGVA4UT0B0CAKQ/viewform?usp=sf_link">I want to adopt a Bana!</a>
            </span></li>
          </ul>
          <h2>Family options</h2>
          <p>If your Bana is already part of the family, you can also:</p>
          <ul>
            <li><span>
              <a target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSe_qQ-gh0HWxjh1YAzw-91pLdRc39F9s0DlQrEb2Pmh-3BoVg/viewform?usp=sf_link">Update your Bana</a>
            </span></li>
            <li><span>
              <a target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfJCQJxykUKyceq6uNajkchR-viHY3koxKVySbgF2k94F4t1A/viewform?usp=sf_link">Add a new born Bana</a>
            </span></li>
            <li><span>
              <a target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLScJYs8wEBv5pS0Ssswm_TT8y1WyTp3QYMqDQeICTesBBNfQ-g/viewform?usp=sf_link">Register adoptions</a>
            </span></li>
          </ul>
          <p>&nbsp;</p>
          <hr />
          <p>
            The Bana Fam Tree is an initative of You-et © {new Date().getFullYear()}
          </p>
        </div>
      </header>
    )
  }
}

export default Header
