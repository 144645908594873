import React from "react"

import './layout.scss'
import Header from'../header/header'

const Layout = ({ location, title, children, treeRef }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="layout" data-is-root-path={isRootPath}>
      <Header treeRef={treeRef} />
      <main>
        {children}
      </main>
    </div>
  )
}

export default Layout
