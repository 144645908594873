import React from "react"

import Plant from '../plant/plant'

class Leaf extends React.Component {
  constructor(props) {
    super(props);
    this.staticTag = `#bana-${this.props.leaf.id}`
    this.state = {
      isToggleOn: this.props.location.hash === this.staticTag,
      hashTag: this.staticTag
    };

    this.toggleSelection = this.toggleSelection.bind(this);
    this.unselect = this.unselect.bind(this);
    this.select = this.select.bind(this);
    this.unselectWithChilderen = this.unselectWithChilderen.bind(this);

    const arrLength = this.props.leaf.children.length;
    this.elRefs = arrLength > 0 ? Array(arrLength).fill().map((_, i) => React.createRef()) : false;
    this.markerRef = React.createRef()
  }
  toggleSelection(e) {
    e.stopPropagation();

    if(this.state.isToggleOn) {
      this.unselect();

    } else {
      this.select();
    }
  }
  unselect() {
    this.setState({hashTag: this.staticTag, isToggleOn: false});
  }
  select() {
    this.props.unselectAllEvent();
    this.setState(state => ({hashTag: '/', isToggleOn: true}));
  }
  unselectWithChilderen() {
    this.unselect();
    if (this.elRefs){
      this.elRefs.forEach(leafRef => {leafRef.current.unselectWithChilderen();});
    }
  }
  render () {
    const leaf = this.props.leaf

    if (!leaf || leaf.length === 0) {
      return (
        <p>
          Leaf not found.
        </p>
      )
    }

    const oneChild = leaf.children.length === 1
    const moreChild = leaf.children.length > 1

    let leafChildren = ''
    if (leaf.children.length > 0) {
      leafChildren =
        <ul className={leaf.children.length > 1 ? 'leaf__children' : 'leaf__child'}>
          {leaf.children.map((child, i) => {return(<Leaf leaf={child} key={`leaf-${child.id}`} unselectAllEvent={this.props.unselectAllEvent} ref={this.elRefs[i]} location={this.props.location} />);})}
        </ul>
    }

    return (

      <li className="leaf">
        <div className="leaf__head">
          <span ref={this.markerRef} className="leaf__marker" id={"bana-" + leaf.id} />
          <div className={`leaf__content
            ${moreChild ? "leaf__content--with-children" :""}
            ${oneChild ? "leaf__content--with-child" :""}
            ${!leaf.first ? "leaf__content--daughter" :""}
            ${this.state.isToggleOn ? "leaf__content--selected" :""}
          `}>
            <Plant plant={leaf} toggleFunction={this.toggleSelection} leafHashTag={this.state.hashTag}/>
          </div>
        </div>
        {leafChildren}
      </li>
    )
  }
}

export default Leaf
