import React from "react"
import Minimap from 'react-minimap';

import './minimap.scss'


class MyMinimap extends React.Component {
  constructor(props) {
    super(props);
    this.smallWidth = "250"
    this.state = {
      width: this.smallWidth,
      isBig: false,
      isClosed: false
    };

    this.toggleClose = this.toggleClose.bind(this);
    this.toggleSize = this.toggleSize.bind(this);
    this.setSmall = this.setSmall.bind(this);
    this.setBig = this.setBig.bind(this);
  }
  toggleClose() {
    this.setState({isClosed: !this.state.isClosed});
  }
  toggleSize() {
    if(this.state.width === this.smallWidth) {
      this.setBig();
    } else {
      this.setSmall();
    }
  }
  setSmall() {
    this.setState({isBig: !this.state.isBig, width: this.smallWidth});
  }
  setBig() {
    const { innerWidth: width } = window;
    this.setState({isBig: !this.state.isBig, width: width-300});
  }
  render () {
    return (
      <>
        <div className={`minimap__toggle-close ${this.state.isClosed ? 'minimap--closed' : ''}`} onClick={this.toggleClose}  role="button" tabIndex={0}>
          <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 960 814.3" style={{enableBackground:"new 0 0 960 814.3"}}>
          	<path className="minimap__toggle-close-left" d="M537.6,401.8l212.3-211.1c15.9-15.8,15.9-41.3,0-57.1c-15.9-15.8-41.6-15.8-57.4,0L453.6,371.2
          		c-8.5,8.4-12.3,19.6-11.7,30.6c-0.6,11,3.2,22.2,11.7,30.6L692.5,670c15.9,15.8,41.6,15.8,57.4,0s15.9-41.3,0-57.1L537.6,401.8z"/>
          	<path className="minimap__toggle-close-right" d="M422.4,401.8L210.1,612.9c-15.9,15.8-15.9,41.3,0,57.1s41.6,15.8,57.4,0l239-237.6c8.5-8.4,12.3-19.6,11.7-30.6
          		c0.6-11-3.2-22.2-11.7-30.6l-239-237.6c-15.9-15.8-41.6-15.8-57.4,0s-15.9,41.3,0,57.1L422.4,401.8z"/>
          </svg>
        </div>
        <div className={`minimap__toggle-big ${this.state.isClosed ? 'minimap--closed' : ''} ${this.state.isBig ? 'minimap--big' : ''}`} onClick={this.toggleSize} role="button" tabIndex={0}>
          <svg version="1.1" id="Capa_3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 960 814.3" style={{enableBackground:"new 0 0 960 814.3"}}>
            <path className="minimap__toggle-big-down" d="M268.1,619l0.8-214.5c0.1-22.4-18-40.4-40.4-40.4S188,382.4,188,404.7l-1,252.2c-0.1,12,5.2,22.6,13.4,29.9
          c7.4,8.2,18,13.4,29.9,13.4l252.2-1c22.4-0.1,40.6-18.2,40.6-40.6c0-22.3-18-40.4-40.4-40.4L268.1,619z"/>
            <path className="minimap__toggle-big-up" d="M692,195.2l-0.8,214.5c-0.1,22.4,18,40.4,40.4,40.4s40.6-18.2,40.6-40.6l0.9-252.1c0.1-12-5.2-22.6-13.4-29.9
        		c-7.4-8.2-18-13.4-29.9-13.4l-252.1,0.9c-22.4,0.1-40.6,18.2-40.6,40.6s18,40.4,40.4,40.4L692,195.2z"/>
          </svg>
        </div>
        <Minimap selector=".plant" keepAspectRatio={true} width={this.state.width} className={`minimap-container ${this.state.isClosed ? 'minimap--closed' : ''} ${this.state.isBig ? 'minimap--big' : ''}`}>
          {this.props.children}
        </Minimap>
      </>
    )
  }
}

export default MyMinimap
