import React from "react"
import { graphql } from "gatsby"
import SimpleReactLightbox from 'simple-react-lightbox'

import "../components/base-styling/base.scss"
import BanasData from "../data/banas.json"
import Layout from "../components/layout/home"
import Tree from "../components/tree/tree"
import Seo from "../components/seo"

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const treeRef = React.createRef();

  return (
    <SimpleReactLightbox>
      <Layout location={location} title={siteTitle} treeRef={treeRef}>
        <Seo title="Banana Plants" />
        <Tree leafs={BanasData.items} location={location} ref={treeRef} />
      </Layout>
    </SimpleReactLightbox>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
