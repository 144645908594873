import React from "react"
import { SRLWrapper } from "simple-react-lightbox";

import './tree.scss'
import Leaf from './leaf'
import Minimap from "../minimap/minimap"

class Tree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 1
    };
    this.staticMin = 0.4;

    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.zoomReset = this.zoomReset.bind(this);

    this.motherLeafRef = React.createRef();
    this.unselectAll = this.unselectAll.bind(this);
  }
  unselectAll () {
    this.motherLeafRef.current.unselectWithChilderen();
  }
  selectMother () {
    this.motherLeafRef.current.unselectWithChilderen();
    this.motherLeafRef.current.select();
  }
  zoomIn() {
    this.setState({zoom: Math.min(this.state.zoom + 0.2, 1)});
  }
  zoomReset() {
    this.setState({zoom: 1});
  }
  zoomOut() {
    this.setState({zoom: Math.max(this.state.zoom - 0.2, this.staticMin)});
  }
  render () {
    const leafs = this.props.leafs

    if (!leafs || leafs.length === 0) {
      return (
        <p>
          No data found. Add markdown posts to "data/banas.json".
        </p>
      )
    }

    const motherLeaf = leafs[0];

    return (
      <div className="tree__wrapper">
        <div className={`minimap__toggle-min ${this.state.zoom > this.staticMin ? '' : 'minimap__toggle-off'}`} onClick={this.zoomOut} role="button" tabIndex={0}>
          <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 960 814.3" style={{enableBackground:"new 0 0 960 814.3"}}>
            <path className="minimap__toggle-loop" d="M480,752.2c-46.6,0-91.8-9.1-134.3-27.1c-41.1-17.4-78-42.3-109.7-73.9c-31.7-31.7-56.6-68.6-73.9-109.7 c-18-42.6-27.1-87.8-27.1-134.3c0-46.6,9.1-91.8,27.1-134.3c17.4-41.1,42.3-78,73.9-109.7c31.7-31.7,68.6-56.6,109.7-73.9 c42.6-18,87.8-27.1,134.3-27.1c46.6,0,91.8,9.1,134.3,27.1c41.1,17.4,78,42.3,109.7,73.9c31.7,31.7,56.6,68.6,73.9,109.7 c18,42.6,27.1,87.8,27.1,134.3c0,46.6-9.1,91.8-27.1,134.3c-17.4,41.1-42.3,78-73.9,109.7c-31.7,31.7-68.6,56.6-109.7,73.9 C571.8,743.1,526.6,752.2,480,752.2z M480,142.1c-70.8,0-137.4,27.6-187.4,77.6c-50.1,50.1-77.6,116.6-77.6,187.4 s27.6,137.4,77.6,187.4c50.1,50.1,116.6,77.6,187.4,77.6s137.4-27.6,187.4-77.6c50.1-50.1,77.6-116.6,77.6-187.4 c0-70.8-27.6-137.4-77.6-187.4C617.4,169.6,550.8,142.1,480,142.1z"/>
            <path className="minimap__toggle-loopvalue" d="M601.6,447.2H358.4c-22.1,0-40-17.9-40-40c0-22.1,17.9-40,40-40h243.1c22.1,0,40,17.9,40,40 C641.6,429.2,623.7,447.2,601.6,447.2z"/>
          </svg>
        </div>
        <div className={`minimap__toggle-reset ${this.state.zoom !== 1 ? '' : 'minimap__toggle-off'}`} onClick={this.zoomReset} role="button" tabIndex={0}>
          <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 960 814.3" style={{enableBackground:"new 0 0 960 814.3"}}>
            <path className="minimap__toggle-loop" d="M480,752.2c-46.6,0-91.8-9.1-134.3-27.1c-41.1-17.4-78-42.3-109.7-73.9c-31.7-31.7-56.6-68.6-73.9-109.7 c-18-42.6-27.1-87.8-27.1-134.3c0-46.6,9.1-91.8,27.1-134.3c17.4-41.1,42.3-78,73.9-109.7c31.7-31.7,68.6-56.6,109.7-73.9 c42.6-18,87.8-27.1,134.3-27.1c46.6,0,91.8,9.1,134.3,27.1c41.1,17.4,78,42.3,109.7,73.9c31.7,31.7,56.6,68.6,73.9,109.7 c18,42.6,27.1,87.8,27.1,134.3c0,46.6-9.1,91.8-27.1,134.3c-17.4,41.1-42.3,78-73.9,109.7c-31.7,31.7-68.6,56.6-109.7,73.9 C571.8,743.1,526.6,752.2,480,752.2z M480,142.1c-70.8,0-137.4,27.6-187.4,77.6c-50.1,50.1-77.6,116.6-77.6,187.4 s27.6,137.4,77.6,187.4c50.1,50.1,116.6,77.6,187.4,77.6s137.4-27.6,187.4-77.6c50.1-50.1,77.6-116.6,77.6-187.4 c0-70.8-27.6-137.4-77.6-187.4C617.4,169.6,550.8,142.1,480,142.1z"/>
            <path className="minimap__toggle-loopvalue" d="M480,568.7c-22.1,0-40-17.9-40-40V285.6c0-22.1,17.9-40,40-40c22.1,0,40,17.9,40,40v243.1 C520,550.8,502.1,568.7,480,568.7z"/>
          </svg>
        </div>
        <div className={`minimap__toggle-max ${this.state.zoom !== 1 ? '' : 'minimap__toggle-off'}`} onClick={this.zoomIn} role="button" tabIndex={0}>
          <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 960 814.3" style={{enableBackground:"new 0 0 960 814.3"}}>
            <path className="minimap__toggle-loop" d="M480,752.2c-46.6,0-91.8-9.1-134.3-27.1c-41.1-17.4-78-42.3-109.7-73.9c-31.7-31.7-56.6-68.6-73.9-109.7 c-18-42.6-27.1-87.8-27.1-134.3c0-46.6,9.1-91.8,27.1-134.3c17.4-41.1,42.3-78,73.9-109.7c31.7-31.7,68.6-56.6,109.7-73.9 c42.6-18,87.8-27.1,134.3-27.1c46.6,0,91.8,9.1,134.3,27.1c41.1,17.4,78,42.3,109.7,73.9c31.7,31.7,56.6,68.6,73.9,109.7 c18,42.6,27.1,87.8,27.1,134.3c0,46.6-9.1,91.8-27.1,134.3c-17.4,41.1-42.3,78-73.9,109.7c-31.7,31.7-68.6,56.6-109.7,73.9 C571.8,743.1,526.6,752.2,480,752.2z M480,142.1c-70.8,0-137.4,27.6-187.4,77.6c-50.1,50.1-77.6,116.6-77.6,187.4 s27.6,137.4,77.6,187.4c50.1,50.1,116.6,77.6,187.4,77.6s137.4-27.6,187.4-77.6c50.1-50.1,77.6-116.6,77.6-187.4 c0-70.8-27.6-137.4-77.6-187.4C617.4,169.6,550.8,142.1,480,142.1z"/>
            <path className="minimap__toggle-loopvalue" d="M480,568.7c-22.1,0-40-17.9-40-40V285.6c0-22.1,17.9-40,40-40c22.1,0,40,17.9,40,40v243.1 C520,550.8,502.1,568.7,480,568.7z"/>
            <path className="minimap__toggle-loopvalue" d="M601.6,447.2H358.4c-22.1,0-40-17.9-40-40c0-22.1,17.9-40,40-40h243.1c22.1,0,40,17.9,40,40 C641.6,429.2,623.7,447.2,601.6,447.2z"/>
          </svg>
        </div>
        <Minimap>
          <div className="tree" style={{transform: `scale(${this.state.zoom})`}}>
            <SRLWrapper options={{buttons:{showDownloadButton: false}}}>
              <ul>
                <Leaf leaf={motherLeaf} key={`leaf-${motherLeaf.id}`} unselectAllEvent={this.unselectAll} ref={this.motherLeafRef} location={this.props.location} />
              </ul>
            </SRLWrapper>
        </div>
      </Minimap>
    </div>
    )
  }
}

export default Tree
