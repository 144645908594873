import React from "react"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import './plant.scss'

class PlantImage extends React.Component {
  render () {
    const data = this.props.data
    const plant = this.props.plant

    const plantImageSrc = `../../images/tid/${plant.id}/${plant.img}`
    const fluid = data.allImageSharp.edges.find((element) => {
      // Match string after final slash
      return (element.node.fluid.src.split('/').pop() === plantImageSrc.split('/').pop())
    })

    if (fluid) {
      return (
        <a href={fluid.src}>
          <Img fluid={fluid.node.fluid} alt={`Photo of ${plant.name ? plant.name : "a nameless bana"}`}/>
        </a>
      )
    }

    return (
      <a href={`no-picture.jpg`}>
        <StaticImage alt={`There is no picture of ${plant.name ? plant.name : "this nameless bana"}`} src={"../../images/tid/no-picture.jpg"} />
      </a>
    )
  }
}


class Plant extends React.Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            allImageSharp {
              edges {
                node {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const plant = this.props.plant

          if (!plant || plant.length === 0) {
            return (
              <p>
                Plant not found.
              </p>
            )
          }

          const itemText = `${plant.name ? plant.name : "This bana"}${plant.born ? " is born on " + plant.born : ""}${(plant.lives && plant.born) ? " and " : ""}${(plant.dead ? " lived in " : " currently lives in ") + (plant.lives ? plant.lives : "the Netherlands")}${(plant.caregiver && plant.lives) ? " with caregiver " + plant.caregiver : ""}${(plant.caregivers && plant.lives) ? " with caregivers " + plant.caregivers : ""}${plant.dead ? ". Sadly, " + (plant.name ? plant.name : "this bana") + " is passed away and gone to plant heaven" : ""}${plant.adoptable ? ". " + (plant.name ? "Little " + plant.name : "This little baby") + " is adoptable and looking for a new home" : ""}.`

          let plantName = <h2><span>unknown</span></h2>
          if (plant.name) { plantName = <h2>{plant.name}</h2> }

          let adoptable = ''
          if (plant.adoptable) {
            adoptable = <a className="plant__adoption" target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLScctmEn9NuVVt57wpz8IPzRK1cFBbkfYCQwrGVA4UT0B0CAKQ/viewform?usp=sf_link">Adopt me!</a>
          }

          return (
            <div className="plant">
              <div className="plant__image">
                <PlantImage  data={data} plant={plant} />
              </div>
              <div className="plant__content">
                <Link className="plant__link" to={this.props.leafHashTag} onClick={this.props.toggleFunction}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" focusable="false"><polygon points="12,1.6 15.2,8.1 22.4,9.1 17.2,14.1 18.4,21.3 12,17.9 5.6,21.3 6.8,14.1 1.7,9.1 8.8,8.1 "></polygon></svg>
                </Link>
                {plantName}
                <p>{itemText}</p>
              </div>
              {adoptable}
            </div>
          )
        }}
      />
    )
  }
}

export default Plant
